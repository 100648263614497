import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Translation App Template | Translation App UI | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/translation-mobile-app/"
    metaDescription="Check out translator, a translation app UI design template to kickstart your project. Simply sign up to Uizard, open the template, and get to work."
    description="
    h2:Translator: A dictionary app UI design template to kickstart your project
    <br/>
    Working on your brand-new translation app design? There is no better way to speed up your project than to get a head start with one of Uizard’s <a:https://uizard.io/templates/mobile-app-templates/>app UI design templates</a>. Our translator app template comes with all the screens you could possibly need, built right in.
    <br/>
    h3:UX design made easy with the power of AI
    <br/>
    Whether you are an expert designer or a UX novice, Uizard's <a:https://uizard.io/design-assistant/>AI design features</a> are here to revolutionize the way you approach app and web design. Scan screenshots of competitor apps and turn them into editable mockups, use the power of generative AI to create CTAs and placeholder text, you can even scan in your hand-drawn wireframes with the click of a button.
    <br/>
    h3:Collaborate and iterate all in one place
    <br/>
    With Uizard, collaboration is easy. Simply share a link to your project or invite your team by email to work together in real time. Collect invaluable feedback on your project and iterate on your designs in no time at all.
    "
    pages={[
      "Home screen mockup with all the core CTAs for your dictionary app design",
      "Translator screen mockup showcasing the core functionality of your design",
      "'Favorite phrases' screen mockup",
      "Interface showing reading, writing, and speech practice for your users",
    ]}
    projectCode="3o1e1YvnreUKMzlBp1wz"
    img1={data.image1.childImageSharp}
    img1alt="Translation mobile app summary"
    img2={data.image2.childImageSharp}
    img2alt="Translation mobile app home screen"
    img3={data.image3.childImageSharp}
    img3alt="Translation mobile app functionality screen"
    img4={data.image4.childImageSharp}
    img4alt="Favorite phrases screen mockup"
    img5={data.image5.childImageSharp}
    img5alt="User interface screen showing reading, writing, speech practice"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/translation-mobile-app/translation-app-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/translation-mobile-app/translation-app-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/translation-mobile-app/translation-app-mobile-text.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/translation-mobile-app/translation-app-mobile-favorites.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/translation-mobile-app/translation-app-mobile-record.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
